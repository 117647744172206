<template>
    <div>
        <b-row>
            <b-col sm="10">
                <Select title="Nº Locação" :showNewRegister="false" field="rent" url="/api/v1/rental/rent/select-all"
                    v-model="selectedRent" />
            </b-col>
            <b-col sm="2">
                <div class="btn-add">
                    <Button _key="btnAddUser" type="primary" :disabled="!selectedRent.id" classIcon="fa-solid fa-plus"
                        size="small" :clicked="addRent" />
                </div>
            </b-col>
        </b-row>
        <div>
            <br>
            <RentLinkedList :cardId="cardId" />
        </div>
    </div>
</template>

<script>


import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import RentLinkedList from './RentLinkedList.vue'

import { mapActions, mapMutations } from "vuex";

export default {
    name: "RentLinked",
    props: ["cardId"],
    components: {
        InputTag, Select, Button, RentLinkedList
    },
    data() {
        return {
            selectedRent: {},
            urlCreate: "/api/v1/crm/card/create-rent-linked",
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        addRent() {
            let params = {
                url: this.urlCreate, obj: {
                    id: this.cardId,
                    rentId: this.selectedRent.id,
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                this.selectedRent = {};
                this.addEvent({ name: "rentLinkedAdded" });
                this.removeLoading(["btnAddUser"]);
            });
        },
    }
}

</script>
<style scoped>
.btn-add {
    margin-top: 38px;
}
</style>