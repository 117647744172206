<template>
    <div class="div-card">
        <div class="div-tags">
            <div class="side-by-side" v-for="item in card.listTag">
                <div class="div-frame text-center" :style="'background:' + item.color">
                    <div class="title-name"> {{ item.name }}</div>
                </div>
            </div>
        </div>

        <div class="limited-title div-name title" @click="execute">
            <i class="fa-solid fa-building"></i>
            {{ card.customerName }}
        </div>
        <div v-if="card.description" class="div-card-description limited-title">
            {{ card.description }}
        </div>
        <div>
            <b-row>
                <b-col sm="1">
                    <img :src="card.avatar" class="user-photo">
                </b-col>
                <b-col sm="9">

                    <div v-if="card.totalComment > 0" class="side-by-side div-note">
                        <i class="fa-regular fa-comments title"></i>
                        <span class="title"> {{ card.totalComment }}</span>
                    </div>

                    <div v-if="card.totalRent > 0" class="side-by-side div-note">
                        <i class="fas fa-exchange-alt title"></i>
                        <span class="title"> {{ card.totalRent }} {{ card.totalValueRent | currency }}</span>
                    </div>

                    <!--  <div v-if="card.listJob.length > 0" class="side-by-side div-job"
                        :style="'color:' + card.totalJob.color">
                        <i class="fa-regular fa-calendar"></i>
                        {{ card.listJob.length }}
                    </div>-->
                    <!-- <div v-if="card.checkList.total > 0" class="side-by-side div-check">
                        <i v-if="card.checkList.done != card.checkList.total" class="fa-solid fa-check"></i>
                        <i v-else class="fa-regular fa-check-double"></i>
                        {{ card.checkList.done }}/{{ card.checkList.total }}
                    </div>-->
                </b-col>
                <b-col sm="1">
                    <div class="div-icon-trash" @click="confirmRemove()">
                        <i class="fa-sharp fa-solid fa-trash"></i>
                    </div>
                </b-col>
            </b-row>
        </div>
        <Modal title="Tem certeza que deseja excluir?" :width="520" :height="750" v-show="showModal('remove')">
            <Alert type="warning">
                <span>
                    Atenção: ao excluir um cartão, todos os registros relacionados também serão excluídos. Esta ação é
                    irreversível.
                </span>
            </Alert>
            <Confirmation :isModal="false" title="Deseja excluir?" type="danger" :confirmed="remove" />
        </Modal>
    </div>
</template>
<script>

import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    components: {
        Alert,
        Modal,
        Confirmation
    },
    props: {
        card: {
            type: Object,
            default: () => ({})
        },
        params: Object,
        clicked: Function,
    },
    data() {
        return {
            urlDeleteAllApi: "/api/v1/crm/card/delete"
        }
    },
    methods: {
        ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
        ...mapActions("generic", ["deleteAllApi"]),
        execute() {
            if (this.clicked) this.clicked(this.params);
        },
        confirmRemove() {
            this.openModal("remove");
        },
        remove() {
            let params = {
                url: this.urlDeleteAllApi,
                selected: [this.card.id],
            };
            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "cardRemoved" });
                    this.hideModal("remove");
                }
                this.removeLoading(["confirm"]);
            });
        },
    },
    computed: {
        ...mapGetters("generic", ["showModal"]),
    },
}
</script>
<style scoped>
.div-card-description {
    margin: 10px;
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    color: #535252;
    background-color: #F0F0F0;
    border-radius: 20px;
}

.div-card {
    margin-bottom: 10px;
    border: 1px solid #e8eaed;
    background-color: white;
    border-radius: 10px !important;
    padding: 10px;
    cursor: move;
}


.user-photo {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    margin-bottom: 3px;
}

.div-check {
    margin-left: 10px;
}

.div-job {
    margin-left: 10px;
}

.div-note {
    margin-left: 10px;
    color: rgb(46, 45, 45);
}

.div-chat {
    margin-left: 10px;
    color: darkgreen;
}

.div-name {
    cursor: pointer;
    color: #3f529b;
}

.div-name:hover {
    text-decoration: underline;
}

.limited-title {
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.div-icon-trash {
    color: red;
    cursor: pointer;
    opacity: 0.5;
}

.div-icon-trash:hover {
    color: red;
    opacity: 10;
}

.div-tags {
    width: 300px;
}


.div-frame {
    border-radius: 20px;
    margin: 2px;
    padding-left: 5px;
    padding-right: 5px;
    height: 18px;
}

.title-name {
    font-size: 10px;
    color: white;
    margin-bottom: 50px;
}
</style>