<template>
  <div>
    <b-row>
      <b-col sm="12">
        <Select title="Cliente" field="customer" :formName="formName" :required="true" :markFormDirty="false"
          :showNewRegister="false" url="/api/v1/crm/customer/select-all" v-model="card.customer">
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <InputText title="Descrição" field="name" :formName="formName" :required="false" :maxLength="2000"
          v-model="card.description" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button _key="saveCard" type="success" title="Criar" :disabled="!isFormValid(formName)"
            classIcon="fa-solid fa-circle-plus" size="medium" :clicked="saveCard" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import Card from "@/components/modules/crm/card/Card.js";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "CardCreate",
  props: {
    step: Object,
  },
  components: { Button, InputText, Select },
  data() {
    return {
      frameId: this.$route.params.id,
      formName: "CardConversationCreate",
      card: new Card(),
      urlCreate: "/api/v1/crm/card/create",
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveCard() {
      this.card.frameId = this.frameId;
      this.card.stepId = this.step.id;
      this.card.userId = this.userLogged.user.id;
      let params = { url: this.urlCreate, obj: this.card };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "cardCreated" });
          this.hideModal();
        }
        this.removeLoading(["saveCard"]);
      });
    }
  },
};
</script>
