export default class Card {
    constructor() {
        this.id = "";
        this.frameId = "";
        this.customer = { id: "", content: "" };
        this.description = "";
        this.stepId = "";
        this.userId = "";
        this.tags = [];
    }
    update(data) {
        this.id = data.id;
        this.customer = data.customer;
        this.description = data.description;
    }
}